.cookiesPage {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    margin-bottom: 2em;
  }
  
  .content {
    max-width: 800px;
    text-align: left;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    
    .heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: .5rem;

      font-weight: bold;
      text-align: center;
    }

    .paragraph {
      ol {
        padding-left : 1rem;
      }

      ol > ol > li {
        list-style-type: lower-alpha;
      }
    }

    a {
      color: #007bff;
      text-decoration: none;
    }
  }