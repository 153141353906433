.CookieBar {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 90%;

    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #f8f8f8;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    border-radius: 15px;

    z-index: 9999;
  }
  
  .CookieBar__text {
    margin: 0;
    font-size: 0.95rem;
    color: #333;
    
    a {
      color: #007bff;
      text-decoration: underline;
  
      &:hover {
        color: #0056b3;
        text-decoration: none;
      }
    }
  }
  
  .CookieBar__button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    font-size: 0.9rem;
    
    &:hover {
      background-color: #0056b3;
    }
  }