@import './../../global.sass'

.Investment
  width: 100%
  border: 1px solid $border
  border-radius: 4px
  display: flex
  align-items: center
  justify-content: flex-start
  padding: 14px
  gap: 13px
  font-size: 17px
  &--gray
    @extend .Investment
    color: $text-lightgray
  img
    width: 76px
    height: 60px
    border-right: 1px solid $border
    padding-right: 14px
  p
    margin: 0
  &__date
    color: darken($border, 20)
    font-size: 0.7em
  &__name
    font-size: 18px
    font-weight: bold
  &__value
    font-size: 18px