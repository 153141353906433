@import './../../global.sass'

.File
  display: flex
  gap: 20px
  align-items: center
  &__inactive
    @extend .File
    color: $border
    img
      filter: invert(70%)

.Documents
  &__grid
    row-gap: 0
  a:nth-child(even)
    @media screen and (min-width: $media-boundary-medium)
      border-left: 3px solid $border
      padding-left: 20px