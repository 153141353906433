@import './../../global.sass'
  
.Discuss
  textarea
    width: 100%
    height: 130px
    resize: none
    border: 1px solid $border
    border-radius: 4px
    padding: 20px
    font-family: 'Inter', sans-serif
    font-size: 18px
  p
    margin: 0