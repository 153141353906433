@import './../../global.sass'

.InvestCard
  display: grid
  gap: 20px
  padding: 20px
  border: 1px solid $border
  border-radius: 4px
  min-height: 107px
  @media screen and ( max-width: $media-boundary-small )
    font-size: 20px
  &__header
    display: flex
    gap: 20px
    align-items: center
  &__image
    max-width: 150px
    object-fit: contain
  &__content
    margin: 0