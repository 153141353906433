@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')
  
// media boundary
$media-boundary-xlarge: 1400px
$media-boundary-large: 1050px
$media-boundary-medium: 800px
$media-boundary-small: 650px
  
$primary: rgb(21, 106, 173)
$background: #FAFAFA
$gray: #F5F5F5
$text-gray: #101010
$text-lightgray: #888888
$border: #BBBBBB

*, *::before, *::after
  box-sizing: border-box

::selection
  color: white
  background: darken($primary, 10)

body
  margin: 0
  padding: 0
  font-family: 'Montserrat', sans-serif
  background: $background
  scroll-behavior: smooth

a
  text-decoration: none
  color: inherit
  cursor: pointer
  &:hover
    text-decoration: underline

.grid
  display: grid
  width: 100%
  &--6-3
    @extend .grid
    grid-column-gap: 20px
    grid-row-gap: 40px
    @media screen and (min-width: $media-boundary-large)
      grid-template-columns: repeat(6, 1fr)
    @media screen and (max-width: $media-boundary-large)
      grid-template-columns: repeat(3, 1fr)
  &--2-1
    @extend .grid
    gap: 20px
    @media screen and (min-width: $media-boundary-medium)
      grid-template-columns: repeat(2, 1fr)
    @media screen and (max-width: $media-boundary-medium)
      grid-template-columns: 1fr
  &--2-1-small
    @extend .grid
    gap: 20px
    @media screen and (min-width: $media-boundary-xlarge)
      grid-template-columns: repeat(2, 1fr)
    @media screen and (max-width: $media-boundary-medium)
      grid-template-columns: 1fr
  &--4-1
    @extend .grid
    gap: 60px
    @media screen and (min-width: $media-boundary-large)
      grid-template-columns: repeat(4, 1fr)
    @media screen and (max-width: $media-boundary-large)
      grid-template-columns: 1fr
  &--3-2-1
    @extend .grid
    gap: 20px
    @media screen and (min-width: $media-boundary-large)
      grid-template-columns: repeat(3, 1fr)
    @media screen and (max-width: $media-boundary-large) and (min-width: $media-boundary-medium)
      grid-template-columns: repeat(2, 1fr)
    @media screen and (max-width: $media-boundary-medium)
      grid-template-columns: 1fr
  &--xl--3-2-1
    @extend .grid
    gap: 20px
    @media screen and (min-width: $media-boundary-xlarge)
      grid-template-columns: repeat(3, 1fr)
    @media screen and (max-width: $media-boundary-xlarge) and (min-width: $media-boundary-large)
      grid-template-columns: repeat(2, 1fr)
    @media screen and (max-width: $media-boundary-large)
      grid-template-columns: 1fr
  &--1
    @extend .grid
    gap: 20px
  &--4-2-1
    gap: 20px
    @media screen and (min-width: $media-boundary-xlarge)
      display: grid
      grid-template-columns: repeat(4, 1fr)
    @media screen and (max-width: $media-boundary-xlarge) and (min-width: $media-boundary-medium)
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-template-areas: "item-4 item-3" "item-2 item-1"
      .item-1
        grid-area: item-1
      .item-2
        grid-area: item-2
      .item-3
        grid-area: item-3
      .item-4
        grid-area: item-4
    @media screen and (max-width: $media-boundary-medium)
      display: flex
      flex-wrap: wrap-reverse
  &--custom-Invest
    gap: 20px
    @media screen and (min-width: $media-boundary-large)
      display: grid
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr
      grid-template-rows: 1fr 1fr
      grid-template-areas: "item-1 item-1 item-1 item-2 item-2 item-2" "item-3 item-3 item-4 item-4 item-5 item-5"
      .item-1
        grid-area: item-5
      .item-2
        grid-area: item-4
      .item-3
        grid-area: item-3
      .item-4
        grid-area: item-2
      .item-5
        grid-area: item-1
    @media screen and (max-width: $media-boundary-large)
      display: grid
  &--custom-Invest2
    gap: 20px
    h4
      background: $primary
      color: white
      padding: 20px 10px
      border-radius: 4px
      height: 70px
      display: flex
      align-items: center
      justify-content: center
    .invest__column-name--empty
        display: none
    @media screen and (min-width: $media-boundary-xlarge)
      display: grid
      grid-template-columns: repeat(5, 1fr)
      grid-template-areas: "heading-item-0 heading-item-1 heading-item-1 heading-item-3 heading-item-3" "item-1 item-2 item-3 item-4 item-5"
      #heading-item-0
        grid-area: heading-item-0
      #heading-item-1
        grid-area: heading-item-1
      #heading-item-3
        grid-area: heading-item-3
      h4
        margin-bottom: 0
    @media screen and (max-width: $media-boundary-xlarge) and (min-width: $media-boundary-large)
      display: grid
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr
      grid-template-rows: 100px 1fr 100px 1fr
      grid-template-areas: "heading-item-0 heading-item-0  heading-item-1 heading-item-1  heading-item-1 heading-item-1" "item-1 item-1 item-2 item-2 item-3 item-3" "heading-item-3 heading-item-3 heading-item-3 heading-item-3 heading-item-3 heading-item-3" "item-4 item-4 item-4 item-5 item-5 item-5"
      .item-1
        grid-area: item-1
      .item-2
        grid-area: item-2
      .item-3
        grid-area: item-3
      .item-4
        grid-area: item-4
      .item-5
        grid-area: item-5
      #heading-item-0
        grid-area: heading-item-0
      #heading-item-1
        grid-area: heading-item-1
      #heading-item-3
        grid-area: heading-item-3
      h4
        margin-bottom: 0
    @media screen and (max-width: $media-boundary-large)
      display: grid
      h4
        margin-bottom: 0
  &--6-5-3-2-1
    gap: 20px
    @media screen and (min-width: $media-boundary-xlarge)
      display: grid
      grid-template-columns: repeat(6, 1fr)
    @media screen and (max-width: $media-boundary-xlarge) and (min-width: $media-boundary-large)
      display: grid
      grid-template-columns: repeat(5, 1fr)
    @media screen and (max-width: $media-boundary-large) and (min-width: $media-boundary-medium)
      display: grid
      grid-template-columns: repeat(3, 1fr)
    @media screen and (max-width: $media-boundary-medium) and (min-width: $media-boundary-small)
      display: grid
      grid-template-columns: repeat(2, 1fr)
    @media screen and (max-width: $media-boundary-small)
      display: grid
      grid-template-columns: repeat(1, 1fr)

.button
  border-radius: 4px
  border: none
  transition: 200ms cubic-bezier(.4,0,.6,0)
  &:hover
    cursor: pointer
    background: lighten($primary, 10)
  &:active
    transform: translateY(5px)
  &--primary
    @extend .button
    color: white
    background: $primary
    @media screen and (min-width: $media-boundary-medium)
      padding: 25px 45px
      font-size: 25px
    @media screen and (max-width: $media-boundary-medium)
      padding: 15px 25px
      font-size: 20px
      width: 100%

.w100
  width: 100%

.flex
  display: flex

.justify-between
  justify-content: space-between

.justify-center
  justify-content: center

.align-center
  align-items: center

.shrink-none
  display: flex
  flex-shrink: 0

.wrap
  flex-wrap: wrap

.text-center
  text-align: center

.grid
  display: grid
  gap: 20px

.gap-20
  gap: 20px

.vgap-60
  column-gap: 60px

.overflow-y
  overflow-y: auto

.h100
  height: 100vh

.flex-direction-column
  flex-direction: column