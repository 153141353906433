@import './../../global.sass'

.ExtensibleCard
  padding: 20px 20px 0px 20px
  border: 1px solid $border
  border-radius: 4px
  cursor: pointer
  h4
    margin: 0
    font-size: 25px
  &__title
    display: grid
    grid-template-columns: 1fr 30px
  &__arrow
    transition: 200ms
    height: 30px
    width: 30px
    border: 1px solid $border
    border-radius: 15px
    text-align: center
    &--rotated
      @extend .ExtensibleCard__arrow
      transform: rotate(180deg)
  &__content
    overflow-y: hidden
    transition: 200ms
    height: 0
    padding-top: 20px
    font-size: 22px
    cursor: auto
    &--visible
      @extend .ExtensibleCard__content
      height: auto
      padding: 20px 0
      