@import "./colors.scss";

@mixin fullHeight {
  min-height: calc(100vh - 98px - 396px);

  @media (min-width: 486px) {
    min-height: calc(100vh - 98px - 308px);
  }

  @media (min-width: 617px) {
    min-height: calc(100vh - 98px - 219px);
  }

  @media (min-width: 768px) {
    min-height: calc(100vh - 99px - 225px);
  }

  @media (min-width: 1264px) {
    min-height: calc(100vh - 99px - 132px);
  }
}

.voucher {
  display: flex;
  flex-direction: column;

  @include fullHeight;

  &_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    filter: blur(0);
    backdrop-filter: blur(4px);
    z-index: 1;

    &_close {
      display: none;
    }

    &_container {
      padding: 1rem;
      width: auto;
      max-width: 90%;
      height: auto;
      background-color: $white;
      border-radius: 8px;

      h3 {
        margin-bottom: 2rem;
        text-align: center;
        color: $blue;
      }

      input {
        padding: 0.25rem 0.25rem 0.25rem 0.5rem;
        width: 100%;
        font-size: 1.1rem;
        border: none;
        border-bottom: 1px solid $black;
        outline: none;
      }

      button {
        margin: 2rem auto 0;
        padding: 0.8rem 1rem;
        display: block;
        text-transform: uppercase;
        font-weight: 600;
        background-color: $blue;
        color: $white;
        border: 2px solid $blue;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: $white;
          color: $blue;
        }
      }
    }
  }

  h1 {
    padding: 1rem;
    text-align: center;
    color: $blue;
  }

  &_content {
    background-color: $thirdBlue;
    padding: 2rem 1rem 2rem 1rem;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 10%);

    &_item {
      position: relative;
      min-width: 270px;
      max-width: 300px;
      height: 300px;

      svg {
        position: absolute;
        top: 0px;
        left: 20px;
        padding: 0.5rem;
        font-size: 3rem;
        background-color: $thirdBlue;
        color: $white;
        border-radius: 8px;
        z-index: 1;
      }

      h3 {
        padding: 0.5rem 0.5rem 0.5rem 10%;
        text-align: center;
        background-color: #3aa8ff;
        color: #fafafa;
        -webkit-clip-path: polygon(25% 0,100% 0,100% 100%,0 100%);
        clip-path: polygon(25% 0,100% 0,100% 100%,0 100%);
        width: 100%;
        margin-bottom: 0px;
      }

      &_bottom {
        padding: 1rem;
        height: calc(100% - 51px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background-color: $white;

        ul {
          list-style: none;

          li {
            position: relative;
            padding-left: calc(1rem + 10px);
            margin-bottom: 0.5rem;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              border-style: solid;
              border-width: 10px 0 10px 10px;
              border-color: transparent transparent transparent $black;
            }
          }
        }

        button {
          margin: 0 auto;
          padding: 0.8rem 1rem;
          display: block;
          text-transform: uppercase;
          font-weight: 600;
          background-color: #004f8d;
          color: $white;
          border: 2px solid #004f8d;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: $white;
            color: #004f8d;
          }
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .voucher {
    &_content {
      &_item {
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .voucher {
    h1 {
      padding: 2rem 1rem 2rem 5%;
    }

    &_popup {
      top: 0;
      height: 100vh;
    }

    &_content {
      padding: 2rem;
      clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%, 0 15%);
    }
  }
}
