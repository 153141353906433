@import './../../global.sass'

// full
$padding-x: 20px
$padding-y: 17px

// mobile
$m-padding-x: 20px
$m-padding-y: 10px

.header
  width: 100%
  z-index: 100
  background: $primary
  display: flex
  justify-content: space-between
  align-items: center
  height: 100px
  padding: $padding-y $padding-x
  color: white
  font-weight: bold
  transition: 300ms cubic-bezier(.18,.52,.83,.67)
  transition-delay: 50ms
  position: sticky
  top: 0
  &__logo
    display: flex
    flex-wrap: wrap
    justify-content: center
  .slogan
    font-weight: 300
    font-size: 9px
  select
    color: white
    background: none
    outline: none
    border: none
  @media screen and (max-width: $media-boundary-large)
    height: 80px
    padding: $m-padding-y $m-padding-x
    select
      color: black
  &__block
    display: flex
    gap: 20px
    align-items: center
    &--mobile
      @extend .header__block
      cursor: pointer
      @media screen and (min-width: $media-boundary-large)
        display: none
  &__option
    font-weight: normal
    letter-spacing: -0.5px
    font-size: 15px
    text-align: center
    &--fav
      @extend .header__option
      display: inline-block
      font-weight: 700
      padding: 5px 10px
      border-radius: 4px
      background: lighten($primary, 30)
      @media screen and (max-width: $media-boundary-large)
        color: white
  &__options
    gap: 17px
    @media screen and (max-width: $media-boundary-large)
      display: grid
      position: fixed
      right: 0
      top: 0
      height: calc(100vh - 80px)
      background: $background
      color: black
      width: 75vw
      transform: translateX(75vw) translateY(80px)
      transition: 200ms cubic-bezier(.4,0,.6,0)
      padding: 20px
      .header__option
        border-bottom: 1px solid $border
        height: 30px
      span
        display: block
    &--visible
      @extend .header__options
      @media screen and (max-width: $media-boundary-large)
        transform: translateX(0) translateY(80px)
        box-shadow: 0px 0px 100px 50px #42445a71
  img
    max-height: 60px
    @media screen and (max-width: $media-boundary-large)
      max-height: calc(70px - $padding-y * 2)
  .svg
    transform-box: fill-box
    transform-origin: center
    .svg__rect
      fill: white
      transition: 200ms
      &:nth-child(1)
        transform: translateY(12px)
      &:nth-child(2)
        transform: translateY(25px)
  .svg--active
    transform-box: fill-box
    transform-origin: center
    .svg__rect
      fill: white
      transition: 200ms
      &:nth-child(1)
        transform: translateX(9px) rotate(45deg)
      &:nth-child(2)
        opacity: 0
      &:nth-child(3)
        transform: translateX(6px)  translateY(28px) rotate(-45deg)