@import './../../global.sass'

.Comment
  display: flex
  flex-wrap: wrap
  border-bottom: 1px solid $border
  gap: 20px
  padding: 20px 0
  font-size: 20px
  &__person-info
    display: flex
    gap: 10px
    img
      width: 45px
      height: 45px
      border: 1px solid $border
      border-radius: 50%
  &:last-child
    border-bottom: none